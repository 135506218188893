.GameStateFooter {
  position: absolute;
  width: 100%;
  height: 0;
  bottom: 0;
  // background-image: url("../../../assets/img/scene_volcano.png");
  // background-repeat: no-repeat;
  // background-size: 100% 100%;

  * {
    color: #ffffff;
    -webkit-text-stroke: 0.05em #100205;
  }

  .u-back {
    transform: rotate(90deg);
    position: absolute;
    left: 2vw;
    bottom: 1vw;
    width: 5vw;
    height: 5vw;
    background-image: url("../../../assets/img/scene_back.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    &:hover {
      cursor: pointer;
    }

    &:active {
      width: 4.9vw;
      height: 4.9vw;
    }
  }

  .u-footer-right {
    position: absolute;
    right: 2vw;
    bottom: 1vw;
    display: flex;
    justify-content: space-between;
    // width: 11vw;

    .u-undo {
      width: 5vw;
      height: 5vw;
      background-image: url("../../../assets/img/scene_undo.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      cursor: pointer;

      &:active {
        width: 4.9vw;
        height: 4.9vw;
      }
    }

    .u-draw {
      width: 5vw;
      height: 5vw;
      background-image: url("../../../assets/img/surrender-icon.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      margin: 0 1vw;

      &:active {
        width: 4.9vw;
        height: 4.9vw;
      }
    }
  }
}
