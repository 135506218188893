.GameSelect {
	background-image: url("../../../assets/img/modal_back.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	position: relative;
	height: 100%;
	display: grid;
	align-items: center;

	.u-container {
		position: relative;
		width: 70vw;
		height: calc(70vw * 0.6);
		top: 1vw;
		margin: auto;
		background-image: url("../../../assets/img/level_back.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		text-align: center;
		vertical-align: middle;

		.u-content {
			position: relative;
			width: 50vw;
			height: calc(50vw * 0.6);
		}
		.u-ribbon {
			position: absolute;
			width: 60%;
			height: 6vw;
			left: 20%;
			top: -2.5vw;
			background-image: url("../../../assets/img/ribbon.png");
			background-repeat: no-repeat;
			background-size: 100% 100%;
			font-size: 3vw;
			text-align: center;
			font-family: "HaettenschweilerRegular", fantasy, Courier, monospace;
			color: #fbfae2;
			-webkit-text-stroke: 0.05em #792a4d;
		}
		.u-content {
			position: absolute;
			bottom: 0;
			width: 100%;
			height: calc(70vw * 0.6 - 3vw);
			display: flex;
			justify-content: space-evenly;
			align-items: center;

			.u-item {
				position: relative;
				width: 20vw;

				.u-item-image {
					width: 100%;
				}

				.u-item-text {
					position: absolute;
					width: 100%;
					height: 33%;
					bottom: 0;
					display: grid;
					text-align: center;
					align-items: center;
					font-family: "HaettenschweilerRegular", fantasy, Courier, monospace;
					color: #fbfae2;
					-webkit-text-stroke: 0.025em #390b13;
					font-size: 1.5vw;
				}

				.u-item-image:active {
					width: 19.9vw;
				}

				&:hover {
					cursor: pointer;
				}
			}
		}
	}
}
