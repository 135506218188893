.refund {
  font-family: "HaettenschweilerRegular";
  color: #fbfae2;
  &-container {
    position: relative;
    width: 40vw;
    height: 30vw;
    margin: auto;
    background-image: url("../../../assets/img/back_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    &-msg {
      height: 25vw;
      padding: 5vw;
      display: grid;
      text-align: center;
      align-items: center;
      font-size: 2vw;
    }
    &-btn_group {
      position: absolute;
      bottom: 1vw;
      width: 100%;
      padding: 1vw;
      display: flex;
      justify-content: space-evenly;
      text-align: center;
      &-yes {
        display: grid;
        align-items: center;
        width: 15vw;
        padding: 0.1vw 1vw;
        background-image: url("../../../assets/img/victory_button.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        font-size: 2.5vw;
        -webkit-text-stroke: 0.05em #0d6c6e;
        cursor: pointer;
        &:active {
          font-size: 2.45vw;
        }
      }
      &-no {
        display: grid;
        align-items: center;
        width: 15vw;
        padding: 0.1vw 1vw;
        background-image: url("../../../assets/img/victory_button.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        font-size: 2.5vw;
        -webkit-text-stroke: 0.05em #0d6c6e;
        cursor: pointer;
        &:active {
          font-size: 2.45vw;
        }
      }
    }
  }
}
