.GameStateHeader {
  position: absolute;
  width: 100%;
  height: 0;
  top: 0;

  * {
    color: #ffffff;
    -webkit-text-stroke: 0.05em #100205;
  }

  .u-you-container {
    position: absolute;
    top: 1vw;
    left: 2vw;
    width: 16vw;

    .u-black {
      position: absolute;
      top: 1vw;
      left: 1vw;
      width: 100%;
      height: 3vw;
      background-color: rgba($color: #000000, $alpha: 0.4);
      border: 1px solid #9789bd;
      border-radius: 1.5vw;
    }

    .u-info {
      position: absolute;
      top: 0;
      left: 0;

      .u-info-img-back {
        width: 8vw;
        height: 8vw;
        background-image: url("../../../assets/img/scene_user_bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: grid;
        align-items: center;
        text-align: center;

        .u-info-img {
          width: 6.4vw;
          height: 6.4vw;
          background-image: url("../../../assets/img/scene_user.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          border-radius: 3.2vw;
          margin: auto;
        }
      }

      .u-info-name {
        padding: 0.1vw;
        display: grid;
        text-align: center;
        align-items: center;
        font-family: "ErasBoldItc";
        font-size: 1.5vw;
        font-weight: bold;
      }
    }

    .u-left-banner {
      position: absolute;
      top: 1vw;
      right: -2vw;
      width: 3vw;
      height: 3vw;
      background-image: url("../../../assets/img/left_banner.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;

      &.hide {
        display: none;
      }

      &.show {
        display: block;
      }
    }
  }

  .u-time-container {
    position: absolute;
    top: 1vw;
    transform: translate3d(-50%, 0, 0);
    left: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .u-time {
      background-color: rgba($color: #000000, $alpha: 0.4);
      border: 1px solid #9789bd;
      width: 14vw;
      height: 2.8vw;
      border-radius: 1.4vw;
      text-align: center;
      align-items: center;
      font-family: "ErasBoldItc";
      font-size: 2vw;
    }

    .u-clock {
      width: 5vw;
      height: 5vw;
      margin: 0 1vw;
      background-image: url("../../../assets/img/scene_clock.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  .u-opponent-container {
    position: absolute;
    width: 16vw;
    top: 1vw;
    right: 2vw;

    .u-black {
      position: absolute;
      top: 1vw;
      right: 1vw;
      width: 100%;
      height: 3vw;
      background-color: rgba($color: #000000, $alpha: 0.4);
      border: 1px solid #9789bd;
      border-radius: 1.5vw;
    }

    .u-info {
      position: absolute;
      top: 0;
      right: 0;

      .u-info-img-back {
        width: 8vw;
        height: 8vw;
        background-image: url("../../../assets/img/scene_user_bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: grid;
        align-items: center;
        text-align: center;

        .u-info-img {
          width: 6.4vw;
          height: 6.4vw;
          background-image: url("../../../assets/img/scene_user.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          border-radius: 3.2vw;
          margin: auto;
        }
      }

      .u-info-name {
        padding: 0.1vw;
        display: grid;
        text-align: center;
        align-items: center;
        font-family: "ErasBoldItc";
        font-size: 1.5vw;
        font-weight: bold;
      }
    }

    .u-right-banner {
      position: absolute;
      top: 1vw;
      left: -2vw;
      width: 3vw;
      height: 3vw;
      background-image: url("../../../assets/img/right_banner.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;

      &.hide {
        display: none;
      }

      &.show {
        display: block;
      }
    }
  }
}
