@keyframes pawnPieceAnim {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.pawn {
  background-image: url("../../../assets/img/1v1_back.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 78vw;
  height: calc(70vw * 0.6);
  position: absolute;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding: 3.5vw 3.5vw;
  transform: translate3d(-50%, -50%, 0);
  left: 50%;
  top: 0;

  &-desc {
    width: 100%;
    font-size: 1.5vw;
    font-family: Arial, Helvetica, sans-serif;
    color: #fdf9e8;
    text-align: center;
    background-color: #20122466;
    border-radius: 2vw;
    padding: 1vw 2vw;
    height: fit-content;
    line-height: 1.3;
  }

  &-cards {
    margin-top: 3vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    align-items: flex-start;
  }

  &-card {
    width: 22%;
    margin: 2.5vw 1vw;
    position: relative;
    height: 18.5vw;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    transition: all 0.25s;

    &:hover {
      cursor: pointer;
    }

    &.active {
      animation: pawnPieceAnim 0.5s linear infinite;
    }

    &-symbol-image {
      position: absolute;
      background-size: 100% 100%;
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 9vw;
      &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &-icon {
          width: 1.7vw;
          height: 1.7vw;
          margin-right: 1vw;
        }

        &-title {
          font-size: 2vw;
          color: #f7cf29;
          font-family: "HaettenschweilerRegular";
          letter-spacing: 0.02em;
          line-height: 1;
        }
      }

      &-desc {
        margin-top: 2.5vw;
        font-size: 2vw;
        color: white;
        font-family: "HaettenschweilerRegular";
        letter-spacing: 0.02em;
        line-height: 1;
      }
    }
  }

  &-button {
    margin-top: 1.5vw;
    background-image: url("../../../assets/img/confirm_btn_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 14vw;
    height: 3.6vw;
    cursor: pointer;
    text-align: center;
    color: #f9f8cb;
    font-size: 2vw;
    font-family: "HaettenschweilerRegular";
    letter-spacing: 0.01em;
    line-height: 1.3;
    text-shadow: 1px 1px 0 #0c6c6d, -1px 1px 0 #0c6c6d, 1px -1px 0 #0c6c6d,
      -1px -1px 0 #0c6c6d, 0px 1px 0 #0c6c6d, 0px -1px 0 #0c6c6d,
      -1px 0px 0 #0c6c6d, 1px 0px 0 #0c6c6d, 2px 2px 0 #0c6c6d,
      -2px 2px 0 #0c6c6d, 2px -2px 0 #0c6c6d, -2px -2px 0 #0c6c6d,
      0px 2px 0 #0c6c6d, 0px -2px 0 #0c6c6d, -2px 0px 0 #0c6c6d,
      2px 0px 0 #0c6c6d, 1px 2px 0 #0c6c6d, -1px 2px 0 #0c6c6d,
      1px -2px 0 #0c6c6d, -1px -2px 0 #0c6c6d, 2px 1px 0 #0c6c6d,
      -2px 1px 0 #0c6c6d, 2px -1px 0 #0c6c6d, -2px -1px 0 #0c6c6d;
  }
}
