.Popup {
  display: grid !important;
  align-items: center;

  .form {
    width: 47vw;
    height: calc(70vw * 0.5);
    background-image: url("../../../assets/img/back_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    transform: translate3d(-50%, -50%, 0);
    left: 50%;
    top: calc(70vw * 0.25);

    .content {
      text-align: left;
      font-family: "HaettenschweilerRegular";
      letter-spacing: 0.01em;
      color: #f9f8cb;
      font-size: 2vw;
      vertical-align: middle;
      padding: 0 10%;
      position: relative;
      transform: translateY(-50%);
      top: 42%;
      text-align: center;
    }
    .footer {
      position: absolute;
      bottom: 3vw;
      display: flex;
      width: 100%;
      padding: 0 3vw;
      justify-content: space-between;

      &.center {
        justify-content: center;
      }

      .button-div {
        cursor: pointer;
        margin: 0 1.5vw;
        padding: 0 1.5vw;
        background-image: url("../../../assets/img/blue_button_bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 5vw;
        text-align: center;
        color: #f9f8cb;
        font-size: 3.5vw;
        font-family: "HaettenschweilerRegular";
        letter-spacing: 0.01em;
        line-height: 1.25;
        text-shadow: 1px 1px 0 #0c6c6d, -1px 1px 0 #0c6c6d, 1px -1px 0 #0c6c6d,
          -1px -1px 0 #0c6c6d, 0px 1px 0 #0c6c6d, 0px -1px 0 #0c6c6d,
          -1px 0px 0 #0c6c6d, 1px 0px 0 #0c6c6d, 2px 2px 0 #0c6c6d,
          -2px 2px 0 #0c6c6d, 2px -2px 0 #0c6c6d, -2px -2px 0 #0c6c6d,
          0px 2px 0 #0c6c6d, 0px -2px 0 #0c6c6d, -2px 0px 0 #0c6c6d,
          2px 0px 0 #0c6c6d, 1px 2px 0 #0c6c6d, -1px 2px 0 #0c6c6d,
          1px -2px 0 #0c6c6d, -1px -2px 0 #0c6c6d, 2px 1px 0 #0c6c6d,
          -2px 1px 0 #0c6c6d, 2px -1px 0 #0c6c6d, -2px -1px 0 #0c6c6d;
      }
      .button-div:active {
        font-size: 3.45vw;
      }
    }
  }
}
