.GameScene {
  position: relative;
  display: grid;
  align-items: center;
  text-align: center;
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/img/modal_back.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .game-container {
    position: relative;
    margin: auto;
    .game-canvas {
      width: 100%;
      height: 100%;
    }
  }
}
