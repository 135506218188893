@keyframes invenAnim {
    0%   {transform: scale(1);}
    50%   {transform: scale(1.3);}
    100%   {transform: scale(1);}
}

.inventory {
    position: absolute;
    transform: translate3d(0, -50%, 0);
    top: 50%;

    width: 8vw;
    height: 50%;

    background: #016da3de;
    border: 0.3vw solid #413348e0;
    border-radius: 1vw;

    transition: all 1s;

    display: flex;
    align-items: center;
    justify-content: center;

    &.show {
        left: -1vw;
    }

    &.hide {
        left: -8vw;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transition: all 0.25s;

        .item {
            position: relative;
            margin: 1.5vw 0;
            width: 4.5vw;

            img {
                width: 100%;
            }

            &.enable {
                opacity: 1;
            }

            &.disable {
                opacity: 0.2;
            }

            &.active {
                animation: invenAnim .5s linear infinite;
            }
        }
    }
}