.rooms {
  background-image: url("../../../assets/img/modal_back.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &-container {
    background-image: url("../../../assets/img/1v1_back.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 78vw;
    height: calc(70vw * 0.6);
    position: relative;
    display: flex;
    justify-content: center;
    padding: 3.5vw 3.5vw;
    &-header {
      background-image: url("../../../assets/img/1v1_header.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border-width: 0;
      background-color: transparent;
      position: absolute;
      top: -2.3vw;
      width: 16vw;
      height: 5vw;
      text-align: center;
      vertical-align: middle;
      line-height: 1.7;
      font-size: 3vw;
      font-family: "HaettenschweilerRegular";
      color: #f9f8cb;
      letter-spacing: 0.025em;
      text-shadow: 1px 1px 0 #0c6c6d, -1px 1px 0 #0c6c6d, 1px -1px 0 #0c6c6d,
        -1px -1px 0 #0c6c6d, 0px 1px 0 #0c6c6d, 0px -1px 0 #0c6c6d,
        -1px 0px 0 #0c6c6d, 1px 0px 0 #0c6c6d, 2px 2px 0 #0c6c6d,
        -2px 2px 0 #0c6c6d, 2px -2px 0 #0c6c6d, -2px -2px 0 #0c6c6d,
        0px 2px 0 #0c6c6d, 0px -2px 0 #0c6c6d, -2px 0px 0 #0c6c6d,
        2px 0px 0 #0c6c6d, 1px 2px 0 #0c6c6d, -1px 2px 0 #0c6c6d,
        1px -2px 0 #0c6c6d, -1px -2px 0 #0c6c6d, 2px 1px 0 #0c6c6d,
        -2px 1px 0 #0c6c6d, 2px -1px 0 #0c6c6d, -2px -1px 0 #0c6c6d;
    }
    &-content {
      margin-top: 0.5vw;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      &-desc {
        font-size: 1.5vw;
        font-family: Haettenschweiler, fantasy, Courier, monospace;
        color: #fdf9e8;
        text-align: center;
        background-color: #20122466;
        border-radius: 2vw;
        padding: 1vw 1.5vw;
        height: fit-content;
        line-height: 1.3;
        margin-bottom: 0 !important;
      }
      &-rooms {
        margin-top: 2.8vw;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        align-items: flex-start;
      }
      &-room {
        width: 28%;
        margin: 1.5vw;
        position: relative;
        height: 11vw;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        cursor: pointer;

        &.closed {
          &::after {
            content: "";
            width: 6vw;
            height: 6vw;
            background-image: url('../../../assets/img/coming-soon.png');
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            right: 0;
            top: 0;
          }
        }

        &-icon {
          position: absolute;
          background-size: 100% 100%;
          width: 9.8vw;
          height: 9vw;
          top: -4vw;
        }

        &-texts {
          margin-top: 3vw;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        &-name {
          font-size: 2vw;
          color: #f7cf29;
          font-family: "HaettenschweilerRegular";
          letter-spacing: 0.02em;
          line-height: 1;
        }
        &-pool {
          font-size: 1.9vw;
          color: white;
          font-family: "HaettenschweilerRegular";
          letter-spacing: 0.02em;
          line-height: 1;
        }
        &-desc {
          margin-top: 0.4vw;
          font-size: 1.1vw;
          font-weight: 400;
          color: white;
          font-family: Arial, Helvetica, sans-serif;
          line-height: 1.3;
          text-align: center;
          padding: 0 1.5vw;
          letter-spacing: 0.07em;
        }
      }
    }
  }
}

.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
